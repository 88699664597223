<template>
  <div>
    <BaseDialog title="余额增减" width="520px" class="dialog-warpper" :dialogVisible.sync="balanceVisible">
      <el-form ref="form" :model="form" label-width="160px" :rules="rules">
        <el-form-item label="当前余额：" prop="currentBalance">
          <span class="input-disabled">
            <el-input style="width: 250px" size="small" v-model="form.currentBalance" readonly></el-input>
          </span>
          <span class="ml10">元</span>
        </el-form-item>
        <el-form-item label="余额增减：" prop="balanceChange">
          <el-tooltip placement="top" effect="light">
            <div slot="content">正数代表增加，负数代表减少，根据修改后的数值重新计算会员余额</div>
            <i style="position: absolute; left: -18px; top: 13px; color: blue" class="el-icon-question margin"></i>
          </el-tooltip>
          <el-input
            style="width: 250px"
            size="small"
            v-model="form.balanceChange"
            @blur="handleBlur"
            placeholder="请输入余额增减的值"
          ></el-input>
          <span class="ml10">元</span>
        </el-form-item>
        <el-form-item label="修改后余额：" prop="modifiedBalance">
          <span class="input-disabled">
            <el-input style="width: 250px" size="small" v-model="form.modifiedBalance" readonly></el-input>
          </span>
          <span class="ml10">元</span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            type="textarea"
            style="width: 250px"
            show-word-limit
            maxlength="50"
            :rows="3"
            placeholder="请输入备注信息"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCanceled">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handleConfirmed">确 定</el-button>
      </span>
    </BaseDialog>
    <paymentPassBox
      :loading.sync="loading"
      :passwordVal.sync="passwordVal"
      :payVisible.sync="dialogVisible"
      @commitPassword="commitPassword"
    ></paymentPassBox>
  </div>
</template>

<script>
import { updateBalance, passwordSetted } from '@/api/balanceApi'
import paymentPassBox from '@/views/components/payDialog'
import { mapGetters } from 'vuex'
import { isNumber } from '@/components/avue/utils/validate'

export default {
  components: {
    paymentPassBox
  },
  props: {
    form: {
      type: Object,
      default: {}
    },
    sup_this: {
      type: Object,
      default: () => ({})
    },
    balanceData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    var validateNumber = (rule, value, callback) => {
      value = Number(value)
      if (value === '' || !isNumber(value)) {
        callback(new Error('请输入数字'))
      }
      callback()
    }
    var validateMax = (rule, value, callback) => {
      if (Number(value) > 999999) {
        callback(new Error('最大不超过999999'))
      }
      callback()
    }
    var validateModifiedBalance = (rule, value, callback) => {
      value = Number(value)
      if (value === '' || isNaN(value)) {
        callback(new Error('修改后的余额必须为数字'))
      }
      if (value < 0) {
        callback(new Error('修改后的余额必须为正数'))
      }
      callback()
    }
    return {
      balanceVisible: false,
      loading: false,
      userID: null,
      passwordVal: [],
      dialogVisible: false,
      rules: {
        balanceChange: [
          { required: true, message: '余额增减的值不能为空', trigger: 'blur' },
          { validator: validateNumber, trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' }
        ],
        modifiedBalance: [{ validator: validateModifiedBalance, trigger: 'blur' }],
        remark: [{ required: true, message: '备注信息不能为空', trigger: 'blur' }]
      }
    }
  },

  watch: {
    balanceVisible: {
      handler(val) {
        if (val) {
          const { accountAmount, remark, id } = this.balanceData
          this.form.currentBalance = accountAmount
          this.form.modifiedBalance = accountAmount
          this.userID = id
          this.form.remark = ''
        }
      },
      immediate: true,
      deep: true
    },
    'form.balanceChange'(n) {
      let value = Number(n)
      if (value === '' || isNaN(value)) {
        this.form.modifiedBalance = this.form.currentBalance
      }
      if (String(n) == 'NaN') {
        this.form.balanceChange = ''
      }
    }
  },
  computed: {
    ...mapGetters(['is_super'])
  },
  methods: {
    async commitPassword() {
      let data = {
        updateAccount: this.form.balanceChange,
        userId: this.userID,
        remark: this.form.remark
      }
      if (!this.is_super) {
        const approveConfirmPassword = this.processPaymentPassword()
        if (!approveConfirmPassword) {
          return
        }
        data = Object.assign(data, { approveConfirmPassword })
      }
      this.loading = true

      try {
        const { code } = await updateBalance(data)
        if (!$SUC(code)) {
          this.onSuccessMessage()
          this.dialogVisible = false
          this.loading = false
        }
      } catch (error) {
        // this.form.balanceChange = 0
        this.loading = false
      }
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    },
    handleBlur() {
      const balanceChange = Number(this.form.balanceChange).toFixed(2)
      const modifiedBalance = (this.form.currentBalance + Number(this.form.balanceChange)).toFixed(2)
      const formData = {
        balanceChange,
        modifiedBalance
      }
      this.$emit('updateForm', formData)
    },
    async onSuccessMessage() {
      this.balanceVisible = false
      this.sup_this.init()
      this.$message.success('保存成功')
    },
    async handleConfirmed() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        try {
          if (!this.is_super) {
            const { code, detail } = await passwordSetted({})
            if (!$SUC(code)) {
              // 如果当前登录用户没有设置交易密码，则提示跳转并return
              if (detail == 0) {
                this.$message.warning('当前登录用户未设置交易密码')
              } else {
                this.passwordVal = []
                this.dialogVisible = true
              }
            }
          } else {
            this.commitPassword()
          }
        } catch (error) {}
      })
    },
    handleCanceled() {
      this.balanceVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .input-disabled {
    ::v-deep {
      .el-input__inner {
        background: #f0f0f0;
      }
    }
  }
  .ml10 {
    margin-left: 10px;
  }
}
</style>
